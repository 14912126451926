<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="orderPage" :fields="fields" :search-model="searchModel"
                :show-selection="true" :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="state">
          <el-select v-model="searchModel.state" clearable placeholder="工单状态">
            <el-option v-for="(label, value) in stateMap" :key="value" :label="label" :value="value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="type">
          <el-select v-model="searchModel.type" placeholder="用户类型" clearable>
            <el-option :label="type" :value="key" v-for="(type, key) in typeOptions" :key="type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="webFarmerSearch">
          <el-input v-model="searchModel.webFarmerSearch" clearable placeholder="用户姓名 手机"></el-input>
        </el-form-item>
        <el-form-item label="" prop="webCreateUserSearch" style="width: 150px">
          <el-input v-model="searchModel.webCreateUserSearch" clearable style="width: 150px"
                    placeholder="业务人员手机号 姓名"></el-input>
        </el-form-item>
        <el-form-item label="" prop="webUserSearch" style="width: 150px">
          <el-input v-model="searchModel.webUserSearch" style="width: 150px"
                    clearable placeholder="踏勘人员手机号 姓名 "></el-input>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <el-button @click="handleExport" size="mini" type="primary">批量导出</el-button>
      </template>
    </page-table>
    <el-dialog title="派单" :visible.sync="transfer_dialog_visible" append-to-body
            :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <el-form :model="transferForm" :rules="transferRules" label-width="120px" ref="transferForm"
               v-loading="transfer_form_loading">
        <el-form-item label="工单编号">
          {{ transferForm.id }}
        </el-form-item>
        <el-form-item label="业务员" prop="userId">
          <el-select
                  v-model="transferForm.userId"
                  filterable
                  remote
                  clearable
                  reserve-keyword
                  placeholder="请输入开发商名称"
                  :remote-method="userIdRemoteMethod"
                  :loading="searech_user_id_loading">
            <el-option
                    v-for="item in user_options"
                    :key="item.id"
                    :label="(item.developers ? item.developers.name : '') + ' ' + item.name"
                    :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="transfer_dialog_visible = false">取 消</el-button>
          <el-button @click="transferSubmit('transferForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="工单详情" :visible.sync="info_dialog_visible" append-to-body v-if="curInfo"
               :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <div style="display: flex;flex-wrap: wrap;line-height: 40px;">
        <div style="width: 50%;">工单编号：{{curInfo.id}}</div>
        <div style="width: 50%;">工单状态：{{stateMap[curInfo.state]}}</div>
        <div style="width:100%;">经销商名称：{{curInfo.user && curInfo.user.distributor ? curInfo.user.distributor.name :
          '-'}}
        </div>
        <div style="width: 50%;">踏勘人员姓名：{{curInfo.user?curInfo.user.name : '-'}}</div>
        <div style="width: 50%;">手机号：{{curInfo.user?curInfo.user.phone: '-'}}</div>
        <div style="width: 50%;">用户名称：{{curInfo.farmer?curInfo.farmer.name : '-'}}</div>
        <div style="width: 50%;">用户电话：{{curInfo.farmer? curInfo.farmer.phone: '-'}}</div>
        <div style="width: 100%;" v-if="curInfo.farmer">屋顶地址：{{curInfo.farmer.province || ''}}{{curInfo.farmer.city ||
          ''}}{{curInfo.farmer.area || ''}}{{curInfo.farmer.address || ''}}
        </div>
        <div style="width: 50%;">工单类型：踏勘工单</div>
        <div style="width: 50%;" v-if="curInfo.createUser">创建人：{{curInfo.createUser.name || ''}}</div>
        <div style="width: 50%;">创建时间：{{curInfo.time || ''}}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {orderPage, orderExport, orderClose, salesmanPage, orderTransfer} from "@/api/common"
import downloadBlob from "@/function/downloadBlob"

// 工单状态
const stateMap = {
  WAIT: '待接单',
  SURVEYED: '待踏勘',
  EXAMINE: '审核中',
  SUCCESS: '审核成功',
  FAIL: '审核失败',
  CLOSE: '闭单',
  WAITING_SIGN: '等待签约',
  WORKING: '施工中',
  UPLOAD:'已提并网',
  WAIT_RECEIVE:'待验收',
  OVER: '验收通过'
}
const typeMap = {
  '0': '农户',
  '1': '公共屋顶',
}
export default {
  name: "Orders",
  data() {
    return {
      typeOptions: typeMap,
      searchModel: {
        state: '',
        type: '',
        webFarmerSearch: '',
        webUserSearch: '',
        webCreateUserSearch: '',
      },
      orderPage: orderPage,
      fields: [
        {label: '工单编号', key: 'id',width: 180},
        {
          label: "工单状态", width: 90,
          render({state}, h) {
            return h('div', stateMap[state])
          }
        },
        {
          label: '经销商名称', width: 160,
          render({user}, h) {
            return h('div', user && user.distributor ? user.distributor.name : '')
          }
        },
        {
          label: '踏勘人员姓名',width: 110,
          render({user}, h) {
            return h('div', user ? user.name : '')
          }
        },
        {
          label: "手机号", width: 120,
          render({user}, h) {
            return h('div', user ? user.phone : '')
          }
        },
        {
          label: "用户姓名",width: 110,
          render({farmer}, h) {
            return h('div', farmer ? farmer.name : '')
          }
        },
        {
          label: "用户电话",width: 120,
          render({farmer}, h) {
            return h('div', farmer ? farmer.phone : '')
          }
        },
        {
          label: "用户地址", width: 170, align: 'center',
          render({farmer}, h) {
            let province = farmer ? farmer.province : ''
            let city = farmer ? farmer.city : ''
            let area = farmer ? farmer.area : ''
            let address = farmer ? farmer.address : ''
            return h('div', province + city + area + address)
          }
        },
        {
          label: "工单类型", width: 90,render(_, h) {
            return h('div', '踏勘工单')
          }
        },
        {
          label: "创建人",width: 100, render({createUser}, h) {
              return h('div',createUser? (createUser.name || ''): '')
          }
        },
        {label: '创建时间', key: 'time', width: 180, align: 'center'},
      ],
      actions: [
        {action: 'close', label: '封单', type: 'primary'},
        {action: 'transfer', label: '转单', type: 'primary'},
        {action: 'info', label: '查看详情', type: 'primary'},
      ],
      stateMap: stateMap,
      //
      transfer_form_loading: false,
      transferForm: {
        id: '',
        userId: '',
      },
      transferRules: {
        userId: [
          {required: true, message: '请选择业务员', trigger: 'blur'},
        ],
      },
      transfer_dialog_visible: false,
      searech_user_id_loading: false,
      user_options: [],
      info_dialog_visible: false,
      curInfo: null,
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {

  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    handleExport() {
      let that = this,ids = []
      let selected = that.$refs.table.selection
      for (let item of selected){
        ids.push(item.id)
      }
      let data = Object.assign(that.searchModel, {
        exportIds: ids.length ? ids.join(',') : ''
      })
      orderExport(data).then(res => {
        downloadBlob(res)
      }).catch(err => {
        console.log(err)
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    closeAction(item) {
      let that = this
      that.$confirm('是否确认封单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        orderClose(item.id).then(() => {
          that.$message.success('操作成功');
          that.$refs.table.loadData()
        }).catch(() => {
          that.$message.error('操作失败')
        })
      })
    },
    transferAction(item) {
      this.transferForm = {
        id: item.id,
        userId: '',
      }
      this.transfer_dialog_visible = true
    },
    userIdRemoteMethod(query) {
      if (query == '') return
      let that = this
      that.searech_user_id_loading = true
      salesmanPage({developerName: query}).then(res => {
        that.user_options = res.records
        that.searech_user_id_loading = false
      }).catch(() => {
        that.user_options = []
        that.searech_user_id_loading = false
      })
    },
    transferSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.transfer_form_loading = true
        orderTransfer(that.transferForm.id, that.transferForm.userId).then(() => {
          that.transfer_form_loading = false
          that.$message.success('提交成功')
          that.transfer_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.transfer_form_loading = false
        })
      })
    },
    infoAction(item) {
      let that = this
      that.curInfo = item
      that.info_dialog_visible = true
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
